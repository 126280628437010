<template>
  <div>
    <div class="page-wrapper chiller-theme" style="margin-top: 71px">
      <main class="page-content">
        <div class="container-fluid">
          <ul
            id="breadcrumb"
            class="breadcrumb ProximaNovaRegular mx-4"
            :class="{ introBread: isIntroBreadcrumb }"
          >
            <li>
              <span>Data Connections</span>
            </li>
            <li class="ProximaNovaBold">Welcome</li>
          </ul>
        </div>
        <div class="container mb-5" style="margin-top: 75px">
          <div
            class="
              col-sm-12 col-xs-12 col-md-12 col-lg-12
              d-flex
              justify-center
            "
          >
            <div class="col-sm-12 col-md-7 col-xs-12 col-lg-7">
              <div class="row">
                <div
                  class="w-100"
                  style="height: 209px; margin-top: 24px"
                  v-for="(obj, index) in dataObj"
                  :key="index"
                >
                  <w-data-type-card
                    :dataType="obj.name"
                    :text="obj.description"
                    :image="obj.image"
                    @dataselected="gotoPlatform($event)"
                  ></w-data-type-card>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- modal -->
        <modal
          name="newWelcomePage"
          :width="388"
          class="welcome--modal"
          @closed="hideWelcomePage"
          :clickToClose="false"
          v-show="isStartNewModal"
        >
          <div class="">
            <div class="w-100">
              <div class="row modal-padding">
                <div class="col p-0 welcome ml-4 mt-4 align-start">
                  <img
                    src="../../assets/group-21.png"
                    alt
                    class="welcome--image"
                  />
                </div>
                <div class="col p-0 intro__content">
                  <p class="Welcome-to-Data-Poem ProximaNovaRegular">
                    Welcome to Data Poem
                  </p>
                  <p class="Together-let-us-hel ProximaNovaRegular">
                    Hey Aditya, together, let us help you to find your best recommended model for your next campaign
                  </p>

                  <button
                    color="primary isi"
                    class="btn-effect-intro"
                    size="button--size-m"
                    @click="letsStart"
                  >
                    <span class="btn-text">Get Started</span>
                    <i
                      class="fas fa-arrow-right ml-5 pl-4 d-flex"
                      style="align-items: center"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </modal>
        <!-- end modal -->
      </main>
    </div>
  </div>
</template>
<script>
import DataTypeCard from "@/widgets/DataTypeCard.vue";
export default {
  props: [],
  components: {
    "w-data-type-card": DataTypeCard,
  },
  data() {
    return {
      isWelcomeModal: true,
      isActive: false,
      padding: 0,
      menuMargin: 70,
      username: "Test",
      dataObj: [
        {
          name: "Corporate Data",
          description:
            "Use corporate data to create media ROI at business level.",
          image: "corporate.webp",
        },
        {
          name: "Product Data",
          description: "Use product data to create media ROI at product level.",
          image: "product.webp",
        },
      ],
      isIntroBreadcrumb: false,
    };
  },
  methods: {
    gotoPlatform(e) {
      console.log(e);
      this.$router.push("/data-connection-platform/digital");
    },
    introBreadcrumb(menu) {
      var element = document.getElementById("breadcrumb");
      if (menu === "breadcrumb") {
        element.classList.add("introBread");
      } else {
        element.classList.remove("introBread");
      }
    },
    hideWelcomePage() {
      this.$modal.hide("newWelcomePage");
    },
    showWelcomePage() {
      this.$modal.show("newWelcomePage");
    },
    letsStart() {
      this.isWelcomeModal = false;
      this.nav = "nav";
      this.$modal.hide("newWelcomePage");
    },
  },
  created() {
    sessionStorage.removeItem("kpiMarket");
  },
  computed: {
    isStartNewModal() {
      const modal = this.$route.name == "Data connection" ? true : false;
      return modal;
    },
  },
};
</script>
<style scoped>
.container {
  height: 100%;
}
.breadcrumb {
  background-color: transparent;
  margin-top: 32px;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
.button {
  padding: 0.75rem;
  height: 60px;
  float: right;
  cursor: pointer;
  background-color: #050505;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
}
.btn-effect {
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #cdcdcd;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.btn-effect:hover {
  background-color: #050505;
  color: #f3f3f3;
}
.introBread {
  position: fixed;
  z-index: 1111;
  padding: 30px;
  background: #fff;
}
</style>
